import $, { isEmptyObject } from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

import { slick } from 'slick-carousel-latest';

// Back to Top
$(function () {

  // hide #back-top first
  $("#back-top").hide();

  // fade in #back-top
  $(function () {
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 100) {
        $('#back-top').fadeIn();
      } else {
        $('#back-top').fadeOut();
      }
    });

    // scroll body to 0px on click
    $('#back-top').on("click", function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });

});

$('.mainburger').on('click', function mobileIcon() {
  var ul = document.getElementById("mainmenu");
  var icon = document.getElementById("mainburger");

  if (ul.style.display === "block") {
    icon.classList.remove("active");
  } else {
    icon.classList.add("active");
  }
});

// Kundenmeinungen
$('.carousel').slick({
  autoplay: true,
  adaptiveHeight: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  appendArrows: $('.slider-controls'),
  responsive: [{
    breakpoint: 1200,
    settings: {
      slidesToShow: 2,
      autoplay: true,
      adaptiveHeight: true,
      slidesToScroll: 1,
      infinite: true
    }
  },
  {
    breakpoint: 767,
    settings: {
      autoplay: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true
    }
  },
  {
    breakpoint: 0,
    settings: {
      autoplay: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      centerMode: true,
      centerPadding: '20%'
    }
  }
  ]
});

// Kunblock Slick VERALTET
$('.kundenblock').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendArrows: $('.slider-controls'),
  centerMode: true,
});

$('.slider-nav').slick({
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  asNavFor: '.kundenblock',
  focusOnSelect: true,
  responsive: [{
    breakpoint: 980,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      centerMode: true,
      centerPadding: '20%'
    }
  }
  ]
});

// Digitale Buchführung
if (Foundation.MediaQuery.atLeast('large')) {
  $(".digitaleBuchfuehrungItem").hide();
  $(".digitaleBuchfuehrungItem:first").show();

  $(".contentNavItem").on('click', function () {
    $(".digitaleBuchfuehrungItem").hide();
    var activeTab = $(this).attr("rel");
    $("#" + activeTab).fadeIn();
    if ($(this).attr("rel") == "#digitaleLohnabrechnung") {
      $('.digitaleBuchfuehrungItem').addClass('show');
    } else {
      $('.digitaleBuchfuehrungItem').removeClass('show');
    }
    $(".contentNavItem").removeClass("active");
    $(this).addClass("active");
  });
}

if ($('body').hasClass('anfrage')) {
  // var currentFieldset = 0; // Current tab is set to be the first tab (0)
  // showFieldset(currentFieldset); // Display the current tab
  // var finanzbuchhaltung = $('#finanzbuchhaltung').prop('checked');

  // function showFieldset(n) {
  //   // This function will display the specified tab of the form ...


  //   var x = $("fieldset");
  //   if (x[n] == x[0]) {
  //     x[0].style.display = "flex";
  //     x[0].classList.add('activeForm');
  //     fixStepIndicator(0)
  //     $(".preButtonWrapper").css('display', 'none');
  //   }
  //   if (x[n] == x[1]) {
  //     x[1].style.display = "flex";
  //     x[1].classList.add('activeForm');
  //     fixStepIndicator(1);
  //     $(".preButtonWrapper").css('display', 'flex');
  //   }
  //   if (x[n] == x[2] && finanzbuchhaltung) {
  //     x[2].style.display = "flex";
  //     x[2].classList.add('activeForm');
  //     fixStepIndicator(1);
  //     $(".preButtonWrapper").css('display', 'flex');
  //   }
  //   if (x[n] == x[2] && !finanzbuchhaltung) {
  //     n = 3;
  //   }
  //   if (x[n] == x[3]) {
  //     x[3].style.display = "flex";
  //     x[3].classList.add('activeForm');
  //     fixStepIndicator(2);
  //     $(".preButtonWrapper").css('display', 'flex');
  //   }
  //   if (x[n] == x[4]) {
  //     x[4].style.display = "flex";
  //     x[4].classList.add('activeForm');
  //     fixStepIndicator(3);
  //     $(".preButtonWrapper").css('display', 'flex');
  //   }
  //   // ... and run a function that displays the correct step indicator:
  //   //   $('html, body').animate({
  //   //     scrollTop: $("fieldset")[n].offset().top
  //   // }, 1);
  //   console.log('show: ' + n);
  // }
  
  $('#anfrage__1').css('display', 'flex');
  fixStepIndicator(0)

  $('#anfrage__1 label').on('click', function () {
    $('#anfrage__1').css('display', 'none');
    $('#anfrage__2').css('display', 'flex');
    fixStepIndicator(1);
    $('html, body').animate({
      scrollTop: $(".anfrageTop2").offset().top-150
    }, 300);
  });

  $('#anfrage__2_belege label').on('click', function () {
    $('#anfrage__2_belege').css('display', 'none');
    $('#anfrage__3').css('display', 'flex');
    fixStepIndicator(2);
    $('html, body').animate({
      scrollTop: $(".anfrageTop3").offset().top-150
    }, 300);
  });

  $('#anfrage__3 label').on('click', function () {
    $('#anfrage__3').css('display', 'none');
    $('#finish').css('display', 'flex');
    fixStepIndicator(4);
    $('html, body').animate({
      scrollTop: $(".anfrageTop2").offset().top-150
    }, 300);
  });

  $('#linkToAnfrage-2').on('click', function () {
    $('#anfrage__1').css('display', 'none');
    $('#anfrage__2').css('display', 'flex');
    fixStepIndicator(1);
    $('html, body').animate({
      scrollTop: $(".anfrageTop2").offset().top-150
    }, 300);
    
    console.log(this.id);
  });
  $('#linkBackToAnfrage-1').on('click', function () {
    $('#anfrage__2').css('display', 'none');
    $('#anfrage__1').css('display', 'flex');
    fixStepIndicator(0);
    $('html, body').animate({
      scrollTop: $(".anfrageTop1").offset().top-150
    }, 300);
    console.log(this.id);
  });
  $('#linkToAnfrage-3-OderBelege').on('click', function () {
    if ($('.inpuFinanzbuchhaltung').prop('checked')) {
      $('#anfrage__2').css('display', 'none');
      $('#anfrage__2_belege').css('display', 'flex');
      fixStepIndicator(1);
      $('html, body').animate({
        scrollTop: $(".anfrageTopBelege").offset().top-150
      }, 300);
      console.log(this.id, $('.inpuFinanzbuchhaltung').prop('checked'));
    } else {
      $('#anfrage__2').css('display', 'none');
      $('#anfrage__3').css('display', 'flex');
      fixStepIndicator(2);
      $('html, body').animate({
        scrollTop: $(".anfrageTop3").offset().top-150
      }, 300);
      console.log(this.id, $('.inpuFinanzbuchhaltung').prop('checked'));
    }
  });
  $('#linkToAnfrage-3').on('click', function () {
    $('#anfrage__2_belege').css('display', 'none');
    $('#anfrage__3').css('display', 'flex');
    fixStepIndicator(1);
    $('html, body').animate({
      scrollTop: $(".anfrageTop3").offset().top-150
    }, 300);
    console.log(this.id);
  });
  $('#linkBackToAnfrage-2').on('click', function () {
    $('#anfrage__2_belege').css('display', 'none');
    $('#anfrage__2').css('display', 'flex');
    fixStepIndicator(0);
    $('html, body').animate({
      scrollTop: $(".anfrageTop2").offset().top-150
    }, 300);
    console.log(this.id);
  });
  $('#linkBackToAnfrage-2-OderBelege').on('click', function () {
    if ($('.inpuFinanzbuchhaltung').prop('checked')) {
      $('#anfrage__3').css('display', 'none');
      $('#anfrage__2_belege').css('display', 'flex');
      fixStepIndicator(1);
      $('html, body').animate({
        scrollTop: $(".anfrageTopBelege").offset().top-150
      }, 300);
      console.log(this.id);
    } else {
      $('#anfrage__3').css('display', 'none');
      $('#anfrage__2').css('display', 'flex');
      fixStepIndicator(2);
      $('html, body').animate({
        scrollTop: $(".anfrageTop2").offset().top-150
      }, 300);
      console.log(this.id);
    }
  });
  $('#linkToAnfrage-4').on('click', function () {
    $('#anfrage__3').css('display', 'none');
    $('#finish').css('display', 'flex');
    fixStepIndicator(3);
    $('html, body').animate({
      scrollTop: $("#finish").offset().top-150
    }, 300);
    console.log(this.id);
  });


  // $(".nxtButton").on('click', function nextPrev(n) {
  //   // This function will figure out which tab to display
  //   var x = document.getElementsByTagName("fieldset");
  //   // Exit the function if any field in the current tab is invalid:
  //   if (n == 1 && !validateForm()) return false;
  //   // Hide the current tab:
  //   x[currentFieldset].style.display = "none";
  //   // Increase or decrease the current tab by 1:
  //   currentFieldset = currentFieldset + 1;
  //   // if you have reached the end of the form... :
  //   // if (currentFieldset >= x.length) {
  //   //   //...the form gets submitted:
  //   //   document.getElementById("regForm").submit();
  //   //   return false;
  //   //}
  //   // Otherwise, display the correct tab:
  //   showFieldset(currentFieldset);
  // })

  // $(".preButton").on('click', function nextPrev(n) {
  //   // This function will figure out which tab to display
  //   var x = document.getElementsByTagName("fieldset");
  //   // Exit the function if any field in the current tab is invalid:
  //   if (n == 1 && !validateForm()) return false;
  //   // Hide the current tab:
  //   console.log(n);
  //   console.log('hide: ' + currentFieldset + ' n: ' + n);
  //   if (currentFieldset == 3 && !finanzbuchhaltung) {
  //     currentFieldset = currentFieldset - 2;
  //   } else {
  //     x[currentFieldset].style.display = "none";
  //   }
  //   // Increase or decrease the current tab by 1:
  //   console.log(finanzbuchhaltung);
  //   console.log(!finanzbuchhaltung);
  //   if (currentFieldset == 3 && !finanzbuchhaltung) {
  //     currentFieldset = currentFieldset - 2;
  //   } else {
  //     currentFieldset = currentFieldset - 1;
  //   }
  //   // if you have reached the end of the form... :
  //   // if (currentFieldset >= x.length) {
  //   //   //...the form gets submitted:
  //   //   document.getElementById("regForm").submit();
  //   //   return false;
  //   //}
  //   // Otherwise, display the correct tab:
  //   showFieldset(currentFieldset);
  // })

  // $('input[type="radio"]').on('click', function nextPrev(n) {
  //   // This function will figure out which tab to display
  //   var x = document.getElementsByTagName("fieldset");
  //   // Exit the function if any field in the current tab is invalid:
  //   if (n == 1 && !validateForm()) return false;
  //   // Hide the current tab:
  //   x[currentFieldset].style.display = "none";
  //   // Increase or decrease the current tab by 1:
  //   currentFieldset = currentFieldset + 1;
  //   // if you have reached the end of the form... :
  //   // if (currentFieldset >= x.length) {
  //   //   //...the form gets submitted:
  //   //   document.getElementById("regForm").submit();
  //   //   return false;
  //   //}
  //   // Otherwise, display the correct tab:
  //   showFieldset(currentFieldset);
  // })

  // $(".nxtButtonWrapper figure").on('click', function nextPrev(n) {
  //   // This function will figure out which tab to display
  //   var x = document.getElementsByTagName("fieldset");
  //   // Exit the function if any field in the current tab is invalid:
  //   if (n == 1 && !validateForm()) return false;
  //   // Hide the current tab:
  //   x[currentFieldset].style.display = "none";
  //   // Increase or decrease the current tab by 1:
  //   currentFieldset = currentFieldset + 1;
  //   // if you have reached the end of the form... :
  //   // if (currentFieldset >= x.length) {
  //   //   //...the form gets submitted:
  //   //   document.getElementById("regForm").submit();
  //   //   return false;
  //   //}
  //   // Otherwise, display the correct tab:
  //   showFieldset(currentFieldset);
  // })

  // $(".preButtonWrapper figure").on('click', function nextPrev(n) {
  //   // This function will figure out which tab to display
  //   var x = document.getElementsByTagName("fieldset");
  //   // Exit the function if any field in the current tab is invalid:
  //   if (n == 1 && !validateForm()) return false;
  //   // Hide the current tab:
  //   x[currentFieldset].style.display = "none";
  //   // Increase or decrease the current tab by 1:
  //   currentFieldset = currentFieldset - 1;
  //   // if you have reached the end of the form... :
  //   // if (currentFieldset >= x.length) {
  //   //   //...the form gets submitted:
  //   //   document.getElementById("regForm").submit();
  //   //   return false;
  //   //}
  //   // Otherwise, display the correct tab:
  //   showFieldset(currentFieldset);

  // })

  // function validateForm() {
  //   // This function deals with validation of the form fields
  //   var x, y, i, valid = true;
  //   x = document.getElementsByByTagName("fieldset");
  //   y = x[currentFieldset].getElementsByTagName("input");
  //   // A loop that checks every input field in the current tab:
  //   for (i = 0; i < y.length; i++) {
  //     // If a field is empty...
  //     if (y[i].value == "") {
  //       // add an "invalid" class to the field:
  //       y[i].className += " invalid";
  //       // and set the current valid status to false:
  //       valid = false;
  //     }
  //   }

  //   var checkRadio = $('input[name="anfrage_kundenart"]').prop('checked');
  //   console.log(checkRadio, validateForm());
  //   if (checkRadio != null) {
  //     $(".anfrage_top p").innerHTML
  //       = checkRadio.value
  //       + " radio button checked";
  //     valid = true;
  //   }
  //   else {
  //     $(".anfrage_top p").innerHTML
  //       = "No one selected";
  //     valid = false;
  //   }
  //   // If the valid status is true, mark the step as finished and valid:
  //   if (valid) {
  //     document.getElementsByClassName("indicatorIcon")[currentFieldset].className += " finish";
  //   }
  //   return valid; // return the valid status
  // }

  function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i, x = document.getElementsByClassName("indicatorIcon");
    var span = document.getElementsByClassName("bar")
    // for (i = 0; i < x.length; i++) {
    //   x[i].className = x[i].className.replace(" active", "");
    // }
    if (n) {
      //... and adds the "active" class to the current step:
      x[n].className += " active";
      span[n - 1].className += " active";
    }
  }
}

//kontaktbuttons
$(window).on('load', function () {
  var heroHeight = $('.hero > img').height();
  $('.kontaktButtons').height(heroHeight - (heroHeight * 0.035));
});

$('.hero > img').on('load', function () {
  var heroHeight = $('.hero > img').height();
  $('.kontaktButtons').height(heroHeight - (heroHeight * 0.035));
});

$(window).on('resize', function () {
  var heroHeight = $('.hero > img').height();
  $('.kontaktButtons').height(heroHeight - (heroHeight * 0.035));
});

$(window).on('scroll', function () {
  if ($(window).scrollTop() > 114) {
    var heroHeight = $('.hero > img').height();
    $(".kontaktButtons li a").addClass("small");
    $(".kontaktButtons li a span").css('height', 0);
    $('.kontaktButtons').height('auto');
  } else {
    $(".kontaktButtons li a").removeClass("small");
    $(".kontaktButtons li a span").css('height', 'auto');
    heroHeight = $('.hero > img').height();
    $('.kontaktButtons').height(heroHeight - (heroHeight * 0.035));
  }

  $('.kontaktButtons li a').on('mouseover', function () {
    if ($(window).scrollTop() > 114) {
      $(this).removeClass("small");
      $(this).children('span').css('height', 'auto');
    }
  });
  $('.kontaktButtons li a').on('mouseleave', function () {
    if ($(window).scrollTop() > 114) {
      $(this).addClass("small");
      $(this).children('span').css('height', 0);
    }
  });
});



// Leistungen einblende
$('.leistungenList > .cell').on('mouseover', function () {
  $(this).children('ul').addClass('fade-in');

  $(this).children('ul.fade-in').children('li').each(function (i) {
    var el = this;
    i = (i + 1) * 1000;
    // console.log(i);
    $(this).addClass('fade-in', i);
    //console.log($(this).children('ul.fade-in').children('li'));
  });
});


// digitale Buchführung einblende
$('.digitaleBuchfuehrungBlocks > .cell').on('mouseover', function () {
  $(this).children('ul').addClass('fade-in');

  $(this).children('ul.fade-in').children('li').each(function (i) {
    var el = this;
    i = (i + 1) * 1000;
    // console.log(i);
    $(this).addClass('fade-in', i);
    //console.log($(this).children('ul.fade-in').children('li'));
  });
});

// obtain cookieconsent plugin
var cc = initCookieConsent();

// run plugin with config object
cc.run({
  autorun: true,
  delay: 0,
  current_lang: 'de',
  auto_language: false,
  autoclear_cookies: true,
  cookie_expiration: 365,
  //theme_css: '../dist/assets/css/cookieconsent.css',
  force_consent: true,

  /* New options from v2.4 (everything is optional) */
  // remove_cookie_tables: false              // default: false (if set to true, cookie table's html will not be generated)

  // cookie_domain: location.hostname,       	// default: current domain
  // cookie_path: "/",                        // default: root
  // cookie_same_site: "Lax",

  /* Manage existing <scripts> tags (check readme.md) */
  //page_scripts: true,                         // default: false (by default don't manage existing script tags)

  /* Basic gui options */
  gui_options: {
    consent_modal: {
      layout: 'cloud',               // box(default),cloud,bar
      position: 'bottom center',     // bottom(default),top + left,right,center:=> examples: 'bottom' or 'top right'
      transition: 'slide'            // zoom(default),slide
    },
    settings_modal: {
      layout: 'box',                 // box(default),bar
      // position: 'left',            // right(default),left (available only if bar layout selected)
      transition: 'slide'            // zoom(default),slide
    }
  },

  /* End new options added in v2.4 */

  onAccept: function (cookie) {
    //console.log("onAccept fired ...");
    if (cc.allowedCategory('analytics')) {
      cc.loadScript('https://www.google-analytics.com/analytics.js', function () {
        ga('create', 'UA-203081044-1', 'auto');  //replace UA-XXXXXXXX-Y with your tracking code
        ga('send', 'pageview');
      });
    }
    if (cc.allowedCategory('marketing')) {
      $('.ccWrapper').remove();
      $('.googleMap').append("<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18961.785962588416!2d9.98526947451578!3d53.553782166755994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18ff3cbe8cd83%3A0x1cea89e8596e0df5!2sHT%20Steuerberatungsgesellschaft%20mbH!5e0!3m2!1sde!2sde!4v1623314564894!5m2!1sde!2sde\" width=\"100%\" height=\"616\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\"></iframe>");
    };


  },

  onChange: function (cookie) {
    //console.log("onChange fired ...");
    if (cc.allowedCategory("analytics")) {
      cc.loadScript('https://www.google-analytics.com/analytics.js', function () {
        ga('create', 'UA-203081044-1', 'auto');  //replace UA-XXXXXXXX-Y with your tracking code
        ga('send', 'pageview');
      });
    }
    if (cc.allowedCategory("marketing")) {
      $('.ccWrapper').remove();
      $('.googleMap').append("<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18961.785962588416!2d9.98526947451578!3d53.553782166755994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18ff3cbe8cd83%3A0x1cea89e8596e0df5!2sHT%20Steuerberatungsgesellschaft%20mbH!5e0!3m2!1sde!2sde!4v1623314564894!5m2!1sde!2sde\" width=\"100%\" height=\"616\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\"></iframe>");
      console.log('durch');
    };


  },


  languages: {
    'de': {
      consent_modal: {
        title: "Wir nutzen Cookies, um Ihnen eine optimale Erfahrung auf unserer Website bieten zu können. Sie können die Cookie Einstellungen jederzeit bearbeiten.",
        description: 'Wir verwenden Cookies um Ihre Erfahrungen beim Besuch unserer Website zu verbessern. Einige dieser Cookies sind notwendig, damit die Website funktioniert. Darüber hinaus verwenden wir Drittanbieter-Cookies, die uns helfen, auf Grundlage Ihres Surfverhaltens, dieses zu analysieren. Diese Cookies werden nur in ihrem Browser gespeichert. Sie haben die Möglichkeit nicht notwendigen Cookies zu deaktivieren. <a href="/datenschutz" class="cc-link">Datenschutzerklärung</a>',
        primary_btn: {
          text: 'Alle Akzeptieren',
          role: 'accept_all'      //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Cookie Einstellungen',
          role: 'settings'       //'settings' or 'accept_necessary'
        }
      },
      settings_modal: {
        title: '<div>Cookie Einstellungen</div>',
        save_settings_btn: "Auswahl speichern",
        accept_all_btn: "Alle Akzeptieren",
        close_btn_label: "Schließen",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Gültigkeit" },
          { col4: "Beschreibung" }
        ],
        blocks: [
          {
            title: "Cookie Anwendung",
            description: "Notwendige Cookies sind grundlegend für die Funktion der Website. Dabei handelt es sich lediglich um Cookies, welche die Basis-Funktionalitäten und Sicherheitsfeatures gewährleisten. Personenbezogene Daten werden dabei nicht gespeichert."
          }, {
            title: "Technisch notwendig",
            description: 'Dieses Cookie ist technisch notwendig zum Funktionieren der Seite.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
            },
            cookie_table: [
              {
                col1: 'cc_cookie',
                col2: 'ht-steuern.de',
                col3: "365 Tage",
                col4: "Speichert Ihre Cookie-Einstellungen auf dieser Website."
              }
            ]
          }, {
            title: "Analysezwecke",
            description: "Diese Cookies analysieren Ihr Nutzerverhalten.",
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false,
              reload: 'on_disable'
            },
            cookie_table: [
              {
                col1: '_ga',
                col2: 'ht-steuern.de',
                col3: '2 Jahre',
                col4: "Cookie von Google zur Unterscheidung von Nutzern."
              },
              {
                col1: '_gat',
                col2: 'ht-steuern.de',
                col3: '1 Minute',
                col4: "Verwendet von Google Analytics zum Drosseln von Anfragen."
              },
              {
                col1: '_gid',
                col2: 'ht-steuern.de',
                col3: '1 Tag',
                col4: "Wird von Google benutzt, um Nuterverhalten auszuwerten."
              }
            ]
          }, {
            title: "Marketing",
            description: 'Diese Cookies ermöglichen es ihnen die Google Map angezeigt zu bekommen.',
            toggle: {
              value: 'marketing',
              enabled: false,
              readonly: false,
              reload: 'on_disable'            // New option in v2.4, check readme.md
            },
            cookie_table: [
              {
                col1: 'OTZ',               // New option in v2.4: regex (microsoft clarity cookies)
                col2: 'www.google.com',
                col3: '30 Tage',
                col4: "Wird von Google benutzt, um Nuteraccounts über mehrere Geräte zu koordinieren."
                // path: '/',               // New option in v2.4
                //is_regex: true              // New option in v2.4
              },
              {
                col1: '1P_JAR',               // New option in v2.4: regex (microsoft clarity cookies)
                col2: 'www.google.com',
                col3: '1 Monat',
                col4: "Advertising-Cookie von Google für User-Tracking und zielgerichtete Werbung."
              },
              {
                col1: 'OGPC',               // New option in v2.4: regex (microsoft clarity cookies)
                col2: '.google.com',
                col3: '1 Tag',
                col4: "Dieses Cookie ermöglicht die Funktionalität von Google Maps."
              },
              {
                col1: 'NID',               // New option in v2.4: regex (microsoft clarity cookies)
                col2: '.google.com',
                col3: '2 Jahre',
                col4: "Dieses Cookie wird von Google gesetzt, um ein Profil der Interessen des Website-Besuchers zu erstellen."
              },
              {
                col1: 'CONSENT',               // New option in v2.4: regex (microsoft clarity cookies)
                col2: '.google.com',
                col3: '17 Jahre',
                col4: "Speichert den Zustimmungsstatus des Benutzers für Google-Cookies auf der aktuellen Domäne."
              }
            ]
          }
          //,{
          //    title : "Mehr Informationen",
          //    description: getLoremIpsum() + ' <a class="cc-link" href="https://orestbida.com/contact/">Contact me</a>.',
          //}
        ]
      }
    }
  }
});


function getLoremIpsum() {
  return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
}



var darkmode = false;

